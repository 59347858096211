export class CartPopup {
    #popupElement;
    #selector;
    #timeout;
    #hideAfter;
    #activeClass;

    constructor({ selector, hideAfter = 5_000 } = {}) {
        this.#selector = selector;
        this.#hideAfter = hideAfter;
        this.#activeClass = "is-active";
    }

    open() {
        this.#querySelector();
        this.#dequeueClose();
        this.#popupElement.classList.add(this.#activeClass);
        this.#enqueueClose();
    }

    close() {
        this.#querySelector();
        this.#dequeueClose();
        this.#popupElement.classList.remove(this.#activeClass);
    }

    #enqueueClose() {
        this.#timeout = setTimeout(() => {
            this.#popupElement.classList.remove(this.#activeClass);
            this.#timeout = undefined;
        }, this.#hideAfter);
    }

    #dequeueClose() {
        if (typeof this.#timeout !== "undefined") {
            clearTimeout(this.#timeout);
            this.#timeout = undefined;
        }
    }

    #querySelector() {
        if (this.#popupElement) {
            return;
        }

        this.#popupElement = document.querySelector(this.#selector);
    }
}

export const cartPopupInstance = new CartPopup({ selector: ".cart-popup" });
